var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_c('b-card',[_c('validation-observer',{ref:"tariffsCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Display Name","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Display Name","vid":"display_name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Display Name","autocomplete":"off"},model:{value:(_vm.form.display_name),callback:function ($$v) {_vm.$set(_vm.form, "display_name", $$v)},expression:"form.display_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Price","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Price","vid":"price","rules":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Price","autocomplete":"off"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Currency","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Currency","vid":"currency_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.currencyList,"searchable":false,"reduce":function (currency) { return currency.id; },"label":"code"},model:{value:(_vm.form.currency_id),callback:function ($$v) {_vm.$set(_vm.form, "currency_id", $$v)},expression:"form.currency_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Is VAT"}},[_c('b-form-checkbox',{staticClass:"mr-0",staticStyle:{"height":"40px"},attrs:{"switch":"","inline":""},on:{"input":function($event){_vm.form.vat = null}},model:{value:(_vm.form.is_vat),callback:function ($$v) {_vm.$set(_vm.form, "is_vat", $$v)},expression:"form.is_vat"}})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[(_vm.form.is_vat)?_c('b-form-group',{attrs:{"label":"VAT","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"VAT","vid":"vat","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"VAT","autocomplete":"off"},model:{value:(_vm.form.vat),callback:function ($$v) {_vm.$set(_vm.form, "vat", $$v)},expression:"form.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,34169620)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Download Speed","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Download Speed","vid":"download_speed","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Download Speed","autocomplete":"off"},model:{value:(_vm.form.download_speed),callback:function ($$v) {_vm.$set(_vm.form, "download_speed", $$v)},expression:"form.download_speed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Upload Speed","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Upload Speed","vid":"upload_speed","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Upload Speed","autocomplete":"off"},model:{value:(_vm.form.upload_speed),callback:function ($$v) {_vm.$set(_vm.form, "upload_speed", $$v)},expression:"form.upload_speed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Limit At","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Limit At","vid":"limit_at","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Limit At","autocomplete":"off"},model:{value:(_vm.form.limit_at),callback:function ($$v) {_vm.$set(_vm.form, "limit_at", $$v)},expression:"form.limit_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Burst limit","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Burst limit","vid":"burst_limit","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Burst limit","autocomplete":"off"},model:{value:(_vm.form.burst_limit),callback:function ($$v) {_vm.$set(_vm.form, "burst_limit", $$v)},expression:"form.burst_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Burst threshold","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Burst threshold","vid":"burst_threshold","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Burst threshold","autocomplete":"off"},model:{value:(_vm.form.burst_threshold),callback:function ($$v) {_vm.$set(_vm.form, "burst_threshold", $$v)},expression:"form.burst_threshold"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Burst time","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Burst time","vid":"burst_time","rules":"integer|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Burst time","autocomplete":"off"},model:{value:(_vm.form.burst_time),callback:function ($$v) {_vm.$set(_vm.form, "burst_time", $$v)},expression:"form.burst_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Priority List","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Priority List","vid":"priority"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.priorityList,"searchable":false,"reduce":function (priority) { return priority.id; },"label":"name"},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Owner","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Owner","vid":"company_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.companyList,"reduce":function (company) { return company.id; },"label":"name"},on:{"search":function($event){return _vm.onSearchCompany($event)}},model:{value:(_vm.form.company_id),callback:function ($$v) {_vm.$set(_vm.form, "company_id", $$v)},expression:"form.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Other Tariffs","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Other Tariffs","vid":"otherTariffIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.tariffList,"reduce":function (tariff) { return tariff.id; },"label":"name","searchable":false,"multiple":""},model:{value:(_vm.form.otherTariffIds),callback:function ($$v) {_vm.$set(_vm.form, "otherTariffIds", $$v)},expression:"form.otherTariffIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Is enabled","label-class":"label-class"}},[_c('b-form-checkbox',{staticClass:"mr-0",attrs:{"switch":"","inline":""},model:{value:(_vm.form.is_enabled),callback:function ($$v) {_vm.$set(_vm.form, "is_enabled", $$v)},expression:"form.is_enabled"}})],1)],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }